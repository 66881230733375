import React, { FC, ImgHTMLAttributes } from 'react'
import 'twin.macro'

export const ResponsiveHero: FC<{ url: string } & ImgHTMLAttributes<HTMLImageElement>> = ({ url, ...rest }) => (
  <img
    tw="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
    src={`${url}&w=320&q=80`}
    srcSet={`
      ${url}&w=2000&q=80 2000w,
      ${url}&w=1024&q=80 1024w,
      ${url}&w=768&q=80 768w,
      ${url}&w=640&q=80 640w,
      ${url}&w=480&q=80 480w
    `}
    {...rest}
  />
)
