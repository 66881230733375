import React, { FC } from 'react'
import 'twin.macro'
import { AnalyticsConversionLink } from '../components/analytics/AnalyticsConversionLink'
import { ContentContainer, H2, PageSection } from '../components/layout/Layout'

type BottomCTAProps = {
  cta: string | JSX.Element,
  title: string | JSX.Element,
  text: string | JSX.Element
  ctaSubtext: string | JSX.Element
  ctaTo: string
}

export const BottomCTA: FC<BottomCTAProps> = ({ cta, title, text, ctaSubtext, ctaTo }) => (
  <PageSection tw="bg-gray-100 sm:py-24">
    <ContentContainer tw="lg:flex lg:items-center lg:justify-between">
      <div tw="max-w-xl">
        <H2>
          {title}
        </H2>
        <p tw="mt-4 text-sm sm:text-xl leading-6 sm:leading-7">
          {text}
        </p>
      </div>
      <div tw="mt-8 flex flex-col items-center lg:flex-shrink-0 lg:mt-0">
        <AnalyticsConversionLink to={ctaTo}>
          {cta}
        </AnalyticsConversionLink>

        <p tw="mt-2 text-xs sm:text-sm font-medium leading-7 text-gray-700">
          {ctaSubtext}
        </p>
      </div>
    </ContentContainer>
  </PageSection>
)
