import React, { FC } from 'react'
import tw from 'twin.macro'
import { AnalyticsConversionLink } from '../components/elements'

import { Hero, HeroSubtext } from '../components/layout/Hero'
import { HeroTemplate } from '../components/layout/HeroTemplate'
import { ResponsiveHero } from '../components/layout/Images'
import { ContentContainer, H2, PageSection } from '../components/layout/Layout'
import { ROUTES } from '../components/layout/Navigation'
import { AppScreenshot } from '../content/AppScreenshot'
import { Benefits } from '../content/Benefits'
import { BottomCTA } from '../content/BottomCTA'

const Icon = tw.svg`h-8 w-8`

const Star = () => (
  <svg tw="w-6 h-6 sm:(w-8 h-8) text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
)

const MobileTestimonial = () => (
  <ContentContainer tw="bg-indigo-50 py-6 md:(bg-white py-0 pb-2) " id="testimonials">
    <div tw="md:hidden flex flex-col items-center">
      <h2 tw="font-semibold text-sm text-cool-gray-900">
        Trusted by hundreds of customers in the UK
      </h2>

      <div tw="mt-3 flex flex-col items-center">
        <div tw="flex flex-row items-center">
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>

        <div tw="text-xs mt-3 text-cool-gray-700 max-w-sm text-center">
          <p tw="font-medium">
            "Outstanding. I got my money back within 48 hours. Would definitely recommend."
          </p>
          <p tw="text-right mt-1">- Sharon, Southampton</p>
        </div>
      </div>
    </div>

    <div tw="hidden md:flex flex-col items-center">
      <h2 tw="font-semibold text-3xl font-bold text-cool-gray-900">
        Trusted by hundreds of customers in the UK
      </h2>

      <div tw="mt-3 flex flex-col items-center">
        <div tw="flex flex-row items-center">
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>

        <div tw="text-base mt-3 text-cool-gray-700 max-w-lg text-center">
          <p tw="font-bold">
            "Outstanding. I got my money back within 48 hours. Would definitely recommend."
          </p>
          <p tw="text-right mt-1">- Sharon, Southampton</p>
        </div>
      </div>
    </div>
  </ContentContainer>
)


const Index = () => (
  <HeroTemplate
    hero={(
      <Hero
        title={(
          <>Your Cancellation, Refunded <span tw="text-pink-500">Fast.</span></>
        )}
        body={(
          <>
            <HeroSubtext>
              Travel Agents And Airlines Make It A Big Hassle To Get Your Money Back For Cancelled Flights.
            </HeroSubtext>

            {/* <p tw="mt-5 sm:mt-6 pl-3 py-1 border-l-4 border-gray-900 text-gray-900 font-bold text-xs sm:text-sm"> */}
            <HeroSubtext>
            Our Online Marketplace Of <strong tw="text-pink-500">Solicitors</strong> Is The Fast And Convenient Way To Get Your Money Back.
            </HeroSubtext>
            {/* </p> */}
          </>
        )}
        cta={'Get My Refund'}
        ctaSmallText='Digital Application. Takes under 5 minutes'
        ctaTo={ROUTES.start.case}
      />
    )}
    bottom={<MobileTestimonial />}
  >

    {/* <PageSection>
      <ContentContainer>
        <H2>Popular legal services</H2>

        <div tw="mt-4">
          <div tw="grid grid-flow-col gap-x-2 overflow-auto">
            <ServiceBlob
              title="Family Law"
              imageUrl="https://images.unsplash.com/photo-1503431153573-96e959f4d9b7?ixlib=rb-1.2.1&auto=format&crop=entropy&cs=tinysrgb&fit=crop&ixid=eyJhcHBfaWQiOjF9"
            />
            <ServiceBlob
              title="Renting and Housing"
              imageUrl="https://images.unsplash.com/photo-1464082354059-27db6ce50048?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop"
            />
            <ServiceBlob
              title="Debts and Loans"
              imageUrl="https://images.unsplash.com/photo-1517232117795-40c9d8212a04?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop"
            />
            <ServiceBlob
              title="Contracts"
              imageUrl="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop"
            />
            <ServiceBlob
              title="Employment"
              imageUrl="https://images.unsplash.com/photo-1510682469697-f2953374f076?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop"
            />
            <ServiceBlob
              title="Purchase Disputes"
              imageUrl="https://images.unsplash.com/photo-1513082325166-c105b20374bb?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop"
            />
            <ServiceBlob
              title="Cease and Desist"
              imageUrl="https://images.unsplash.com/photo-1525972475276-711875162dd1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop"
            />
          </div>
        </div>
      </ContentContainer>
    </PageSection> */}


    <Benefits />

    <MidRollCTA
      prompt={<>Our hand-picked network of solicitors are ready help you get your money back</>}
      action="Get My Refund"
    />

    <HowItWorks />
    <BottomCTA
      cta={'Get started now'}
      title={'It\'s Time. Get Your Money Back Now.'}
      text={(
        <>
          <p tw="mt-3">
            Remove the uncertainty and hassle by letting our solicitors do the heavy lifting for you.
          </p>
        </>
      )}
      ctaSubtext={'Digital Application. Takes under 5 minutes'}
      ctaTo={ROUTES.start.case}
    />
  </HeroTemplate>
)

const ServiceBlob: FC<{ title: string, imageUrl: string }> = ({ title, imageUrl }) => (
  <div tw="relative rounded-md h-32 bg-cover bg-center" style={{ width: '150px' }}>
    <div tw="relative p-4 text-white bg-gradient-to-b from-gray-900 to-transparent rounded-md z-20">
      <h4 tw="font-bold">{title}</h4>
    </div>
    <ResponsiveHero
      url={imageUrl}
      tw="h-32 absolute top-0 left-0 z-10 rounded-md"
    />
  </div>
)


type MidrollCTAProps = {
  prompt: JSX.Element | string
  action: string
}
const MidRollCTA: FC<MidrollCTAProps> = ({ action, prompt }) => (
  <PageSection tw="bg-indigo-900 text-white">
    <ContentContainer tw="rounded-none sm:rounded-lg">
      <div tw="flex flex-col lg:flex-row justify-between items-center">
        <p tw="text-base text-white font-semibold mb-6 lg:mb-0 md:px-4 text-center md:text-left">
          {prompt}
        </p>
        <div tw="self-stretch sm:self-auto">
          <AnalyticsConversionLink
            to={ROUTES.start.case}
            tw="flex-shrink-0 px-5 py-2 text-sm font-medium"
          >
            {action}
          </AnalyticsConversionLink>
        </div>
      </div>
    </ContentContainer>
  </PageSection>
)

const HowItWorksItem: FC<{ number: string, icon: JSX.Element, title: string | JSX.Element, body: string | JSX.Element, last?: boolean }> = ({ number, icon, title, body, last = false }) => (
  <li tw="flex relative pb-10 sm:pb-14">
    {last ? null : (
      <div tw="h-full w-12 absolute inset-0 flex items-center justify-center">
        <div tw="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
    )}
    <div tw="flex-shrink-0 w-12 h-12 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
      {icon}
    </div>
    <div tw="flex-grow pl-4">
      {/* <h2 tw="font-medium text-xs sm:text-sm text-gray-900 tracking-wider">STEP {number}</h2> */}
      <p tw="font-medium text-pink-500 font-bold mb-1 text-xl">{number}. {title}</p>
      <p tw="text-gray-900 text-base">{body}</p>
    </div>
  </li>
)

const HowItWorks = () => (
  <PageSection id="how-it-works">
    <ContentContainer>
      <H2>
        Simple And Quick To Use
      </H2>

      <div tw="flex flex-col lg:flex-row mt-6 md:mt-16 justify-between">
        <div tw="max-w-md mx-auto lg:(w-1/2 mx-0)">
          <ul>
          <HowItWorksItem
            number={'1'}
            icon={(
              <Icon xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </Icon>
            )}
            title="Apply online"
            body="Tell us about your flight cancellation using our online platform."
          />

          <HowItWorksItem
            number={'2'}
            icon={(
              <Icon xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </Icon>
            )}
            title="Choose your solicitor"
            body="We'll quote you an affordable fixed fee."
          />

          <HowItWorksItem
            number={'3'}
            icon={(
              <Icon xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </Icon>
            )}
            title="Build your case"
            body="Your specialist solicitor will build you an airtight case."
          />

          <HowItWorksItem
            number={'4'}
            icon={(
              <Icon xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
              </Icon>
            )}
            title="Rest easy"
            body="Your solicitor will take necessary action to get you back your money. Fast."
            last
          />

          </ul>
        </div>

        <div tw="max-w-md w-full mx-auto lg:(mx-0 w-1/2 ml-8)">
          <AppScreenshot small />
        </div>
      </div>
    </ContentContainer>
  </PageSection>
)



export default Index
