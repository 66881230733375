import React, { FC } from 'react'
import tw from 'twin.macro'
import { HeroImage } from '../../content/HeroImage'

import { AnalyticsConversionLink } from '../analytics/AnalyticsConversionLink'
import { ButtonStyle, ContentContainer } from './Layout'

type HeroProps = {
  title: string | JSX.Element,
  body: string | JSX.Element,
  cta: string | JSX.Element
  ctaTo: string
  ctaSmallText: string | JSX.Element
  bottom?: JSX.Element
}

const Star = () => (
  <svg tw="w-6 h-6 sm:(w-6 h-6) text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
)

export const HeroSubtext = tw.p`mb-5 last:mb-0 text-base text-indigo-50 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0`

// tw="mt-10 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"

export const Hero: FC<HeroProps> = ({ title, body, cta, ctaTo, ctaSmallText, bottom }) => (
  <>
    <ContentContainer>
        <div tw="md:flex md:flex-wrap">
          <div tw="md:w-1/2 md:text-left md:pt-16">

            <h1 tw="text-4xl leading-10 font-bold text-white sm:text-5xl sm:leading-none md:text-6xl">
              {title}
            </h1>

            <div tw="mt-5">
              {body}
            </div>

              <div tw="mt-8 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div tw="w-full sm:w-72 flex flex-col items-center">
                  <AnalyticsConversionLink to={ctaTo}>
                    {cta}
                  </AnalyticsConversionLink>

                  <p tw="mt-2 text-xs sm:text-sm leading-7 font-medium text-indigo-400">
                    {ctaSmallText}
                  </p>
                </div>
            </div>
          </div>

          <div tw="hidden md:flex items-end justify-end md:w-1/2 relative">
            <div tw="absolute right-0 bottom-0 z-10 p-4 md:bg-cool-gray-900 xl:bg-transparent">
              <div tw="flex flex-col items-end">
                <p tw="text-white font-bold">
                  Caroline B.
                </p>
                <p tw="text-white text-sm font-light">
                  100+ satisfied clients
                </p>
                <div tw="flex flex-row items-center mt-2">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
              </div>
            </div>
            <div tw="mt-10 w-full absolute left-0 lg:left-28 top-0">
              <HeroImage tw="max-w-md lg:max-w-sm"/>
            </div>
          </div>
        </div>
      </ContentContainer>

    {/* <div tw="sm:text-center lg:text-left">
      <h2 tw="text-4xl leading-10 font-bold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
        {title}
      </h2>

      <div tw="mt-5">
        {body}
      </div>

      <div tw="mt-8 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div tw="w-full sm:w-72 flex flex-col items-center">
          <AnalyticsConversionLink to={ctaTo}>
            {cta}
          </AnalyticsConversionLink>

          <p tw="mt-2 text-xs sm:text-sm leading-7 font-medium text-gray-700">
            {ctaSmallText}
          </p>
        </div>
      </div>
    </div> */}
  </>
)

