import React, { FC } from 'react'
import tw from 'twin.macro'

import image1 from './images/lawyer@0,02x.png'
import image2 from './images/lawyer@0,05x.png'
import image3 from './images/lawyer@0,1x.png'
import image4 from './images/lawyer@0,2x.png'


const Star: FC = props => (
  <svg tw="h-2 w-2 sm:w-4 sm:h-4 text-yellow-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
)


const NavigationItem = tw.div`h-1 mb-1 sm:(h-2 mb-2) bg-gray-200 rounded-full`

const SectionCard = tw.div`w-full flex flex-wrap justify-between items-center shadow-md sm:shadow-lg rounded-lg p-2 sm:p-6 bg-white mb-3`
const SectionProfile = tw.div`flex-shrink-0 w-6 h-6 sm:(w-12 h-12) rounded-full bg-gray-200 mr-2 sm:mr-4`
const SectionText1 = tw.div`h-1 w-10 sm:(h-2 w-16) bg-gray-200 mb-1 rounded-full`
const SectionText2 = tw.div`h-1 w-6 sm:(h-2 w-10) bg-gray-100 mb-1 rounded-full`
const SectionTextCenter = tw.div`h-1 w-6 sm:(h-2 w-10) bg-gray-100 rounded-full mx-auto`

const SectionPillOut = tw.div`w-10 py-1 px-2 sm:(w-16 py-2 px-4) rounded-full mx-auto sm:hidden md:block`
const SectionPillIn = tw.div`hidden sm:block p-1 rounded-full`

export const AppScreenshot = () => (
  <div
    tw="w-full flex rounded-lg bg-white overflow-hidden shadow-lg pt-4"
    style={{ zIndex: 1 }}
  >
    <div tw="h-4 bg-gray-200 absolute top-0 left-0 right-0 rounded-t-lg flex items-center">
      <span tw="h-2 w-2 rounded-full bg-red-500 inline-block mr-1 ml-2"></span>
      <span tw="h-2 w-2 rounded-full bg-orange-400 inline-block mr-1"></span>
      <span tw="h-2 w-2 rounded-full bg-green-500 inline-block mr-1"></span>
    </div>

    <div tw="w-24 sm:w-32 bg-gray-100 px-2 py-4 sm:(px-4 py-8)">
      <div tw="h-2 w-16 bg-gray-300 rounded-full mb-4"></div>
      <div tw="flex items-center mb-4">
        <div tw="h-5 w-5 rounded-full bg-gray-300 mr-3 flex-shrink-0"></div>
        <div>
          <div tw="h-2 w-10 bg-gray-300 rounded-full"></div>
        </div>
      </div>

      <NavigationItem tw="w-16"/>
      <NavigationItem tw="w-10"/>
      <NavigationItem tw="w-20"/>
      <NavigationItem tw="w-6"/>
      <NavigationItem tw="w-16"/>
      <NavigationItem tw="w-10"/>
      <NavigationItem tw="w-20"/>
      <NavigationItem tw="w-6"/>
    </div>
    <div tw="flex-1 p-4 sm:p-6">
      <p tw="text-base sm:text-lg text-gray-700 font-bold mb-3">
        Solicitors for you
      </p>

      <SectionCard>
        <div tw="w-1/2">
          <div tw="flex items-center">
            <SectionProfile />
            <div>
              <SectionText1 />
              <SectionText2 />
              <div tw="flex">
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
            </div>
          </div>
        </div>
        <div tw="w-1/4">
          <SectionPillOut tw="bg-green-100">
            <SectionPillIn tw="bg-green-200" />
          </SectionPillOut>
        </div>
        <div tw="w-1/4 sm:hidden xl:block">
          <SectionTextCenter />
        </div>
      </SectionCard>

      <SectionCard tw="flex-shrink-0">
        <div tw="w-1/2">
          <div tw="flex items-center">
            <SectionProfile />
            <div>
              <SectionText1 />
              <SectionText2 />
              <div tw="flex">
                <Star />
                <Star />
                <Star />
                <Star />
                <Star tw="text-yellow-100" />
              </div>
            </div>
          </div>
        </div>
        <div tw="w-1/4">
          <SectionPillOut tw="bg-orange-100">
            <SectionPillIn tw="bg-orange-200" />
          </SectionPillOut>
        </div>
        <div tw="w-1/4 sm:hidden xl:block">
          <SectionTextCenter />
        </div>
      </SectionCard>

      <SectionCard tw="hidden sm:flex">
        <div tw="w-1/2">
          <div tw="flex items-center">
            <SectionProfile />
            <div>
              <SectionText1 />
              <SectionText2 />
              <div tw="flex">
                <Star />
                <Star />
                <Star />
                <Star tw="text-yellow-100" />
                <Star tw="text-yellow-100" />
              </div>
            </div>
          </div>
        </div>
        <div tw="w-1/4">
          <SectionPillOut tw="bg-blue-100">
            <SectionPillIn tw="bg-blue-200" />
          </SectionPillOut>
        </div>
        <div tw="w-1/4 sm:hidden xl:block">
          <SectionTextCenter />
        </div>
      </SectionCard>

      <div tw="flex justify-center mt-6">
        <div tw="h-3 w-3 sm:(h-4 w-4) bg-gray-200 rounded-full mr-4"></div>
        <div tw="h-3 w-3 sm:(h-4 w-4) bg-gray-200 rounded-full "></div>
      </div>

    </div>
  </div>
)

export const HeroImage: FC = props => (
  <img
    tw="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
    src={image2}
    srcSet={`
      ${image4} 1000w,
      ${image3} 520w,
      ${image2} 260w,
      ${image1} 104w,
    `}
    {...props}
  />
)
