import React, { FC } from 'react'
import 'twin.macro'
import { ContentContainer, H2, PageSection } from '../components/layout/Layout'

import alarm from './images/alarm.svg'
import bolt from './images/bolt.svg'
import rocket from './images/rocket.svg'
import scale from './images/scale.svg'
import tag from './images/tag.svg'
import trophy from './images/trophy.svg'

const BenefitItem: FC<{ icon: string, title: string, content: string }> = ({ icon, title, content }) => (
  <li tw="pb-12 md:pb-0 last:pb-0">
    <div tw="flex flex-col items-start">
      <div tw="flex flex-row items-center">
        <div tw="mr-4 sm:mr-0 flex-shrink-0 flex items-center justify-center h-8 w-8 sm:h-12 sm:w-12 text-white">
          <img tw="h-12 w-12" src={icon} />
        </div>

        <h4 tw="block sm:hidden text-lg leading-6 font-semibold text-gray-900">{title}</h4>
      </div>
      <div tw="mt-4">
        <h4 tw="hidden sm:block text-lg leading-6 font-semibold text-gray-900">{title}</h4>
        <p tw="sm:mt-2 text-base leading-7 text-gray-600">
          {content}
        </p>
      </div>
    </div>
  </li>
)

export const Benefits = () => (
  <PageSection id="benefits" tw="bg-gray-100">
    <ContentContainer>
      <H2>Managed By Top Legal Experts</H2>

      <div tw="mt-6 md:mt-10">
        <ul tw="md:grid md:grid-cols-3 md:gap-x-14 md:gap-y-10">
          <BenefitItem
            icon={tag}
            title='Fixed affordable prices'
            content='No hourly rates, just simple understandable pricing. We use technology to provide legal help more efficiently than traditional law firms and pass those savings to you.'
          />
          <BenefitItem
            icon={trophy}
            title='Experts in airline legal disputes'
            content="We'll pair you with a solicitor that has helped hundreds of flyers get their money back from travel agents and airlines."
          />
          <BenefitItem
            icon={bolt}
            title='Lightning speed communication and support'
            content="You'll get responses in minutes. Access your case from anywhere on mobile and desktop. No need to come to our offices - we're fully remote!"
          />
        </ul>
      </div>
    </ContentContainer>
  </PageSection>
)
